<template>
    <div>
      <v-form
         v-on:submit.prevent="DialogAceptar = true"
          ref="form"
          lazy-validation
      >
        <v-card>
        <v-card-title>
          <span class="headline">{{Accion}}</span>
          <v-spacer></v-spacer>
          <span v-if="Item.id != 0" class="headline">ID: {{Item.id}}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
             <v-row>

               <v-col
                cols="12"
                sm="6"
                md="4"
              >
               <v-select
                    v-model="Item.riesgo"
                    :items="Riesgo"
                    item-text="id"
                    item-value="id"
                    :menu-props="{ maxHeight: '300'}"
                    label="Tipo de Riesgo"
                    persistent-hint
                    ></v-select>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="4"
              >
               <v-select
                  v-model="Item.eps"
                  :items="SINO"
                  item-text="titulo"
                  item-value="id"
                  menu-props="auto"
                  label="Eps activo?"
                  hint="Indique si eps encuentra activo"
                  persistent-hint
                  :rules="[v => v >= 0 || 'Item Requerido']"
                  required
                ></v-select>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="4"
              >
               <v-select
                  v-model="Item.arl"
                  :items="SINO"
                  item-text="titulo"
                  item-value="id"
                  menu-props="auto"
                  label="Arl está activo?"
                  hint="Indique si arl se encuentra activo"
                  persistent-hint
                  :rules="[v => v >= 0 || 'Item Requerido']"
                  required
                ></v-select>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="4"
              >
               <v-select
                  v-model="Item.caja"
                  :items="SINO"
                  item-text="titulo"
                  item-value="id"
                  menu-props="auto"
                  label="Caja está activo?"
                  hint="Indique si caja se encuentra activo"
                  persistent-hint
                  :rules="[v => v >= 0 || 'Item Requerido']"
                  required
                ></v-select>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="4"
              >
               <v-select
                  v-model="Item.pension"
                  :items="SINO"
                  item-text="titulo"
                  item-value="id"
                  menu-props="auto"
                  label="Pensión está activo?"
                  hint="Indique si pensión se encuentra activo"
                  persistent-hint
                  :rules="[v => v >= 0 || 'Item Requerido']"
                  required
                ></v-select>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  v-model="Item.valor_cliente"
                  label="Precio *"
                  hint="Precio a cobrar"
                  persistent-hint
                  required
                ></v-text-field>
              </v-col>

               <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  v-model="Item.comision_afiliacion_regular"
                  label="% Comisión afiliación regular *"
                  hint="Porcentaje de comisión afiliación regular"
                  persistent-hint
                  required
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  v-model="Item.comision_afiliacion_vip"
                  label="% Comisión afiliación vip *"
                  hint="Porcentaje de comisión afiliación vip"
                  persistent-hint
                  required
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  v-model="Item.comision_recaudo_regular"
                  label="% Comisión recaudo regular*"
                  hint="Porcentaje de comisión recaudo regular"
                  persistent-hint
                  required
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  v-model="Item.comision_recaudo_vip"
                  label="% Comisión recaudo vip *"
                  hint="Porcentaje de comisión recaudo vip"
                  persistent-hint
                  required
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  v-model="Item.puntos"
                  label="Puntos *"
                  hint="Número de puntos por venta"
                  persistent-hint
                  required
                ></v-text-field>
              </v-col>


            </v-row>
          </v-container>

          <small>* indica campo requerido</small>

        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="Ocultar()">Cerrar</v-btn>
          <v-btn color="primary" type="submit">Guardar</v-btn>
        </v-card-actions>
      </v-card>
      </v-form>

     <v-dialog v-model="DialogAceptar" persistent max-width="380">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{Accion}}
        </v-card-title>

        <v-card-text class="mt-4">
          <h2>¿Estas seguro de guardar?</h2>
        </v-card-text>
        
        <v-divider></v-divider>

        <v-card-actions class="mt-5">
          <v-spacer></v-spacer>
          <v-btn color="error" @click="DialogAceptar = false">Cancelar</v-btn>
          <v-btn color="primary" @click="Guardar()">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    </div>
</template>

<script>
import { mapState, mapGetters, mapActions} from 'vuex'

export default ({

  props:['Accion','Item','NuevoActivo'],

  watch:{
      Alerta(value){
        if(value.error == false){
          this.Ocultar()
        }
      }
  },

  created (){
    
  },

  data: () => ({
    var: null,
    DialogAceptar: false,

    Riesgo:[
      {id: 1},
      {id: 2},
      {id: 3},
      {id: 4},
      {id: 5},
    ],

    ItemEnviar: {
          id: 0,
          riesgo: null,
          eps: null,
          arl: null,
          caja: null,
          pension: null,
          valor_cliente: null,
          comision_afiliacion_regular: 0,
          comision_recaudo_regular: 0,
          comision_afiliacion_vip: 0,
          comision_recaudo_vip: 0,
          puntos: 0
      },
  }),

  methods: {
     ...mapActions(['NuevoParametrosEps', 'ConsultarParametrosEps',]),

    async Guardar (){

      this.ItemEnviar.id = this.Item.id
      this.ItemEnviar.riesgo = this.Item.riesgo
      this.ItemEnviar.eps = this.Item.eps
      this.ItemEnviar.arl = this.Item.arl
      this.ItemEnviar.caja = this.Item.caja
      this.ItemEnviar.pension = this.Item.pension
      this.ItemEnviar.valor_cliente = this.Item.valor_cliente
      this.ItemEnviar.comision_afiliacion_regular = this.Item.comision_afiliacion_regular
      this.ItemEnviar.comision_recaudo_regular = this.Item.comision_recaudo_regular
      this.ItemEnviar.comision_afiliacion_vip = this.Item.comision_afiliacion_vip
      this.ItemEnviar.comision_recaudo_vip = this.Item.comision_recaudo_vip
      this.ItemEnviar.puntos = this.Item.puntos

      await this.NuevoParametrosEps(this.ItemEnviar)
      await this.ConsultarParametrosEps()
      this.DialogAceptar = false
    },

    Ocultar(){
        this.$emit('NuevoActivo', false)
    }
  },

  computed:{
    ...mapState(['Alerta']),
    ...mapGetters(['SINO',])
  }


})
</script>