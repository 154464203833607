<template>
  <div class="parametroseps">
    <tabla titulo="Parametros EPS" 
          :headers = "headers"
          :arreglo = "ParametrosEps.items"
            />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import tabla from '@/components/parametroseps/';

export default {
  name: 'ParametrosEps',

   components: {
    tabla
  },
  
  created(){
    this.ConsultarParametrosEps()
  },

  data: () =>{
    return{
    headers: [
        {
          text: 'ID',
          align: 'start',
          sortable: true,
          value: 'id',
        },
        { text: 'RIESGO', value: 'riesgo1' },
        { text: 'EPS', value: 'eps1' },
        { text: 'ARL', value: 'arl1' },
        { text: 'CAJA', value: 'caja1' },
        { text: 'PENSIÓN', value: 'pension1' },
        { text: 'PRECIO', value: 'valor_format' },
        { text: 'ACCIONES', value: 'actions', sortable: false },
      ],

    }
  },

  methods: {
    ...mapActions(['ConsultarParametrosEps']),

  },

  computed:{
      ...mapGetters(['ParametrosEps'])
  }
}
</script>
