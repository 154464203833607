<template>
<div>

    <Nuevo  v-if="NuevoActivo" :NuevoActivo="NuevoActivo" :Accion="Accion" :Item="Item" @NuevoActivo="NuevoActivo = $event"/>

  <v-data-table
    :headers="headers"
    :items="arreglo"
    :items-per-page="5"
    :search="busqueda"
    dense
    sort-by="id"
    sort-desc="true"
    class="elevation-12 mt-5"
    :loading="TablaVacia"
    loading-text="Cargando... Espere por favor"
  >
    <template v-slot:top>
      <v-toolbar
        flat
      >
      
      <v-toolbar-title>{{titulo}}</v-toolbar-title>
    
        <v-spacer></v-spacer>
        <!--busqueda-->
        <v-text-field
            v-model="busqueda"
            append-icon="mdi-magnify"
            label="BUSQUEDA"
            single-line
            hide-details
        ></v-text-field>
        <!--busqueda-->

        <v-spacer></v-spacer>

        <v-btn
          color="success"
          dark
          @click="newItem()"
        >
          NUEVO
        </v-btn>

         <!--Popup DELETE-->
        <v-dialog v-model="dialogDelete" max-width="380px">
          <v-card>
             <v-card-title class="text-h5 grey lighten-2">
              Eliminar
             </v-card-title>

              <v-card-text class="mt-0 mb-n5">
                <v-card-title>¿Desea eliminar el ID # {{ItemSelect.id}}?</v-card-title>
              </v-card-text>
              
              <v-divider></v-divider>
            
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" @click="dialogDelete = false">Cerrar</v-btn>
              <v-btn color="primary" @click="deleteItemConfirm">Eliminar</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!--Popup DELETE-->

      </v-toolbar>
    </template>
    <template v-slot:[`item.actions`]="{ item }">

      <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          v-bind="attrs"
          v-on="on"
          small
          @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
      </template>
      <span>Editar</span>
    </v-tooltip>
      
      <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          v-bind="attrs"
          v-on="on"
          small
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
      <span>Eliminar</span>
    </v-tooltip>

    </template>

  </v-data-table>
  </div>
</template>

<script>
  import Nuevo from "./nuevo"
  import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'

  export default {
    components: {
        Nuevo,
    },

    props:['titulo','headers','arreglo'],

    created () {
      this.initialize()
    },

    data: () => ({
      busqueda: '',
      radiobusqueda: null,

      Item: null,
      Accion: null,

      TablaVacia: null,
      items: [],
      
      NuevoActivo: false,

      dialogDelete: false,
      ItemSelect: {id: 0, nombre: ''},
    }),

    watch:{
      arreglo(){
          if(!!this.arreglo){
            if(this.arreglo.lenght > 0){
              this.TablaVacia = true
            }else{
              this.TablaVacia = false
            }
          }
        },
    },

    methods: {
      ...mapMutations([]),
      ...mapActions(['ConsultarParametrosEps','EliminarParametrosEps']),

      initialize () {
        
      },

      newItem (){
        this.Item= {
              id: 0,
              riesgo: null,
              eps: null,
              arl: null,
              caja: null,
              pension: null,
              valor_cliente: null,
              comision_afiliacion_regular: 0,
              comision_recaudo_regular: 0,
              comision_afiliacion_vip: 0,
              comision_recaudo_vip: 0,
              puntos: 0
        }
        this.NuevoActivo = true
        this.Accion = 'Nuevo'
      },

      editItem (item) {
        this.Item = Object.assign({}, item)
        this.NuevoActivo = true
        this.Accion = 'Editar'
      },

      deleteItem (item) {
        this.ItemSelect= Object.assign({}, item)
        this.dialogDelete = true
      },

      async deleteItemConfirm () {
        await this.EliminarParametrosEps(this.ItemSelect)
        this.dialogDelete = false
        await this.ConsultarParametrosEps()
      },
    },

    computed: {
      ...mapState([]),
      ...mapGetters([]),
    },
  }
</script>